<script setup lang="ts">
import { onMounted } from "vue";
import { RouterView, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@store/useAuthStore.ts";
import TopMenu from "@menu/TopMenu.vue";
import View from "@ui/View.vue";
import AuthProvider from "@providers/AuthProvider.vue";
import XTransitionContent from "@ui/XTransitionContent.vue";

const router = useRouter();
const store = useAuthStore();
const { isAuth } = storeToRefs(store);

onMounted(() => {
  if (!isAuth.value) {
    router.push("/");
  }
});
</script>

<template>
  <AuthProvider>
    <View>
      <TopMenu />

      <XTransitionContent>
        <router-view />
      </XTransitionContent>
    </View>
  </AuthProvider>
</template>
