<script setup lang="ts">
withDefaults(
  defineProps<{
    label: string;
    value: string;
    disabled?: boolean;
    checked?: boolean;
  }>(),
  {
    disabled: false,
    checked: false,
    id: "as",
  }
);

const modelValue = defineModel("modelValue");
</script>

<template>
  <div class="x-radio" :class="{ 'x-radio_disabled': disabled }">
    <input
      type="radio"
      :id="value"
      :value="value"
      :disabled="disabled"
      v-model="modelValue"
    />
    <label :for="value">{{ label }}</label>
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.x-radio {
  font-size: 1rem;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 300ms ease-in-out;
  }

  &[disabled] {
    // opacity: 50%;
    background-color: $light-gray;
    color: $gray;
  }

  &_disabled {
    opacity: 50%;
  }
}
</style>
