<script setup lang="ts">
import { ref, reactive } from "vue";
import { cardDeck } from "@config/cardDecks";
import { ButtonNavigation } from "@type/setBuilderFrame";

const emit = defineEmits<{
  (e: "select-deck", buttonNavigation: ButtonNavigation): void;
}>();

const setIdProp = ref({ setId: 0 });

const buttonNavigation = reactive<ButtonNavigation>({
  back: {
    setId: 0,
  },
  next: {
    setId: 0,
  },
});

function handleDeckSelect(id: number) {
  setIdProp.value.setId = id;
  buttonNavigation.next.setId = id;

  emit("select-deck", buttonNavigation);

  return;
}
</script>

<template>
  <div class="deck-list">
    <button
      v-for="deck in cardDeck"
      :for="deck.id.toString()"
      class="deck"
      v-on="
        deck.id !== setIdProp.setId || setIdProp.setId === 0
          ? { click: () => handleDeckSelect(deck.id) }
          : {}
      "
    >
      <div
        class="deck__icon"
        :class="{ deck__icon_selected: deck.id === setIdProp.setId }"
      >
        {{ deck.quantity }}
      </div>

      <div
        class="deck__title"
        :class="{ deck__title_selected: deck.id === setIdProp.setId }"
      >
        {{ deck.name }}
      </div>
    </button>
  </div>
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.deck {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  padding: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  min-width: 100px;
  row-gap: 1rem;
  column-gap: 1rem;

  @include screen($md) {
    row-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  @include screen($lg) {
    row-gap: 2rem;
    column-gap: 2rem;
  }

  &-list {
    display: flex;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 1rem;
  }

  &__icon {
    width: 60px;
    height: 90px;
    background-color: $light-gray;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-in-out;
    color: $black;

    @include screen($md) {
      width: 80px;
      height: 120px;
    }

    @include screen($lg) {
      width: 100px;
      height: 150px;
    }

    &_selected {
      background-color: $blue;
      color: $white;
      transition: all 100ms ease-in-out;
    }
  }

  &__title {
    padding: 0 1rem;
    color: $black;
    background: transparent;
    font-size: $font-size-small;
    transition: all 100ms ease-in-out;

    &_selected {
      background: $beige;
      color: $blue;
      border-radius: 0.5rem;
      transition: all 100ms ease-in-out;
    }
  }
}
</style>
