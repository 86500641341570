<script setup lang="ts"></script>

<template>
  <div class="white-block">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.white-block {
  background-color: $white;
  border-radius: 1rem;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05),
    0 17px 15px -1rem rgba(0, 0, 0, 0.15);
}
</style>
