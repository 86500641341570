<script setup lang="ts"></script>

<template>
  <div class="error-message">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.error-message {
  background-color: $red;
  color: $white;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem auto;
  width: fit-content;
  font-weight: bold;
  transition: all 500ms ease;
}
</style>
