<script setup lang="ts"></script>

<template>
  <div class="content">
    <slot />
  </div>
</template>

<style lang="scss">
.content {
  padding: 0 1rem;

  ol {
    counter-reset: item;
    margin: 0;
    padding: 0 0 0 1rem;
  }
  li {
    display: block;
    margin-bottom: 1rem;
  }

  li:first-child {
    margin-top: 1rem;
  }

  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
}
</style>
