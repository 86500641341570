<script setup lang="ts"></script>

<template>
  <div class="view">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "src/scss/vars" as *;

.view {
  min-height: 100vh;
  // padding: 1rem 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @include screen($lg) {
    max-width: $max-width;
    margin: 0 auto;
  }
}
</style>
