<script setup lang="ts">
import { reactive } from "vue";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { useAuthStore } from "@store/useAuthStore.ts";
import { useErrorStore } from "@store/useErrorStore";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";

const { setAuth } = useAuthStore();
const router = useRouter();

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setAuthError } = errorStore;

const form = reactive({
  username: "",
  password: "",
});

async function handleSubmit() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include",
    };

    // @ts-ignore
    const res = await fetch("https://psy-api.otoselo.com/login", requestOptions)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("error", error));

    if (res?.ok) {
      setAuthError(false);
      setAuth(true);
      router.push("/"); // or to push route from local storage
    } else {
      setAuth(false);
      setAuthError(true, "Неправильний логін або пароль");
      console.log("redirect must be here");
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <XHeader title="Авторизація" />

  <XTransitionFadeIn v-if="error?.auth?.value">
    <XError class="error-message">
      {{ error.auth?.message }}
    </XError>
  </XTransitionFadeIn>

  <XFormBorder>
    <XInput
      v-model:value="form.username"
      name="login"
      type="text"
      label="Електронна пошта"
    />

    <XInput
      v-model:value="form.password"
      name="password"
      type="password"
      label="Пароль"
    />

    <XButton @click="handleSubmit" title="Увійти" />
  </XFormBorder>
</template>

<style scoped lang="scss"></style>
