<script setup lang="ts"></script>

<template>
  <div class="transition-fade-in">
    <slot />
  </div>
</template>

<style>
.transition-fade-in {
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
  animation-name: fadeIn;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
