<script setup lang="ts"></script>

<template>
  <div class="transition-fade-menu">
    <slot />
  </div>
</template>

<style>
.transition-fade-menu {
  animation-fill-mode: forwards;
  animation-duration: 400ms;
  animation-name: fadeMenu;
  opacity: 0;
}

@keyframes fadeMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
