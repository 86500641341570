<script setup lang="ts">
import { provide, reactive, useTemplateRef } from "vue";
import XInput from "@ui/XInput.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
// import { useAuthStore } from "@store/useAuthStore.ts";
import { useErrorStore } from "@store/useErrorStore";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";

// const authStore = useAuthStore();
// const { setShowAuthDialog } = authStore;
// const { showAuthDialog } = storeToRefs(authStore);

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setAuthError } = errorStore;

const form = reactive({
  username: "",
  password: "",
});

const dialogRef = useTemplateRef<HTMLDialogElement>("dialogRef");

const handleUnauthorized = (): void => {
  // setShowAuthDialog(true);
  console.log(dialogRef);
  dialogRef?.value?.showModal();
};

async function handleSubmit() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include",
    };

    // @ts-ignore
    const res = await fetch("https://psy-api.otoselo.com/login", requestOptions)
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("error", error));

    if (res?.ok) {
      setAuthError(false);
      // setShowAuthDialog(false);
      dialogRef?.value?.close();
      // setAuth(true);
      // router.push("/"); // or to push route from local storage
    } else {
      // setAuth(false);
      setAuthError(true, "Неправильний логін або пароль");
      console.log("redirect must be here");
    }
  } catch (error) {
    console.error(error);
  }
}

// Provide the handleUnauthorized function to child components
provide("handleUnauthorized", handleUnauthorized);
</script>

<template>
  <div>
    <slot></slot>

    <Teleport to="body">
      <!-- <div
        v-if="showAuthDialog"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      > -->
      <dialog ref="dialogRef">
        <h3>Потрібно авторизуватись</h3>

        <XTransitionFadeIn v-if="error?.auth?.value">
          <XError class="error-message">
            {{ error.auth?.message }}
          </XError>
        </XTransitionFadeIn>

        <XFormBorder>
          <XInput
            v-model:value="form.username"
            name="login"
            type="text"
            label="Електронна пошта"
          />

          <XInput
            v-model:value="form.password"
            name="password"
            type="password"
            label="Пароль"
          />

          <XButton @click="handleSubmit" title="Увійти" />
        </XFormBorder>
      </dialog>

      <!-- <div class="bg-white rounded-lg p-6 w-96">
          <h2 class="text-2xl font-bold mb-4">Потрібно авторизуватись</h2>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label
                for="username"
                class="block text-sm font-medium text-gray-700"
                >Username</label
              >
              <input
                v-model="username"
                id="username"
                type="text"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div class="mb-4">
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
                >Password</label
              >
              <input
                v-model="password"
                id="password"
                type="password"
                required
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div class="flex justify-end">
              <button
                type="submit"
                class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </button>
            </div>
          </form>
        </div> -->
      <!-- </div> -->
    </Teleport>
  </div>
</template>
