<script setup lang="ts">
var myHeaders = new Headers();
myHeaders.append("Accept", "application/json");
myHeaders.append("Content-Type", "application/json");

// var raw = JSON.stringify({
//   "username": "test@email.com",
//   "password": "123123"
// });

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow',
//   credentials: "include",
// };

// fetch("https://psy-api.otoselo.com/login", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));

//   на регистрацию пост /register с боди:

// {
//     "email": "test@email.com",
//     "password": {
//         "password": "123123",
//         "confirm_password": "123123"
//     }
// }

// на логаут просто постом кинь /logout
</script>

<template>
  <router-view></router-view>
</template>
