import { SessionSet } from "@type/session";
import { ref } from "vue";

export default function useSetBuilderFrame() {
  const sessionSetup = ref<SessionSet>({
    setId: 0,
    useAllCards: null,
    cards: [],
    requiredNumber: 0,
    showCardsFaceUp: null,
  });

  function patchSessionSetup(sessionSetupPart: Partial<SessionSet>) {
    sessionSetup.value = {
      ...sessionSetup.value,
      ...sessionSetupPart,
    };

    return;
  }

  function getCardsNumber() {
    return sessionSetup.value.cards.length;
  }

  return {
    sessionSetup,
    patchSessionSetup,
    getCardsNumber,
  };
}
