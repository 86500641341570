<script setup lang="ts">
import { ref } from "vue";

const emit = defineEmits<{
  (e: "card-numbers", cards: number[]): void;
}>();

const props = defineProps<{
  setId: number;
  deckQuantity: number;
  requiredQuantity: number;
}>();

const selected = ref<number[]>([]);

function cardToggle(card: number) {
  const index = selected.value.indexOf(card);
  if (index === -1) {
    selected.value.push(card);
  } else {
    selected.value.splice(index, 1);
  }

  if (selected.value.length === props.requiredQuantity) {
    emit("card-numbers", selected.value);
  }
}
</script>

<template>
  <div class="cards">
    <label
      v-for="item in deckQuantity"
      :for="String(item)"
      class="image-card-preview"
      :style="{
        border:
          selected.indexOf(item) > -1
            ? '3px solid green'
            : '3px solid transparent',
        opacity:
          selected.indexOf(item) > -1 || selected.length < requiredQuantity
            ? 1
            : 0.5,
        display:
          selected.indexOf(item) > -1 || selected.length < requiredQuantity
            ? 'block'
            : 'none',
      }"
    >
      <input
        type="checkbox"
        :value="item"
        :id="item.toString()"
        :checked="selected.indexOf(item) > -1"
        @click="cardToggle(item)"
        :disabled="
          selected.length === requiredQuantity && !(selected.indexOf(item) > -1)
        "
        style="display: none"
      />
      <img
        :src="`/cards/${setId}/${item}.jpg`"
        alt=""
        class="image-card-preview"
      />
    </label>
  </div>
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.image-card-preview {
  display: block;
  width: auto;
  height: 180px;
  border-radius: 0.5rem;
}
</style>
