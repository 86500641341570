<script setup lang="ts">
import { reactive, ref } from "vue";
import XButton from "@ui/XButton.vue";
import XHeader from "@ui/XHeader.vue";
import { SessionSet } from "@type/session";
import SetBuilderFrame from "src/components/set-builder-frame/SetBuilderFrame.vue";
import { router } from "src/router";

const session = reactive<SessionSet[]>([]);
const isNewSession = ref(true);

function addSetToSession(sessionSet: SessionSet) {
  console.log("inside new session", sessionSet);
  session.push(sessionSet);
  isNewSession.value = false;
}

function handleNewSession() {
  isNewSession.value = true;
}

async function handleSubmitSession() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch("https://psy-api.otoselo.com/session", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ cardSets: session }),
      redirect: "follow",
      credentials: "include",
    });

    const response = await res.text();
    const responseParse = await JSON.parse(response);

    if (responseParse?.sessionId) {
      router.push("/user/session/" + responseParse?.sessionId);
    }

    if (responseParse?.code === 401) {
      console.log("Unauthorized");
      // @ts-ignore
      handleUnauthorized();
    }
  } catch (error) {
    console.error(error);
  }
  return;
}
</script>

<template>
  <XHeader title="Новий сеанс" />

  <div v-if="!isNewSession" class="new-session">
    <div>
      <XButton
        :title="session?.length >= 1 ? 'Дотати ще набір' : 'Додати набір'"
        :disabled="isNewSession"
        @click="handleNewSession"
      />
      <XButton title="Розпочати сеанс" @click="handleSubmitSession" />
    </div>
  </div>
  <div v-if="!isNewSession">
    {{ session }}
  </div>

  <SetBuilderFrame
    v-if="isNewSession"
    @add-card-set-to-session="addSetToSession"
  />
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.new-session {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  padding: 0 1rem;
}
</style>
