import { defineStore } from "pinia";
import { reactive } from "vue";
type ErrorRegisterResponse = {
  email?: string[];
  password?: string[];
  confirm_password?: string[];
};

type ErrorProp = {
  value: boolean;
  message?: string | string[] | ErrorRegisterResponse;
};

type ErrorResponse = {
  auth?: ErrorProp;
  register?: ErrorProp;
};

export const useErrorStore = defineStore("error", () => {
  const error = reactive<ErrorResponse>({});

  function setAuthError(value: boolean, message?: string): void {
    error.auth = { value, message };

    return;
  }

  function setRegisterError(
    value: boolean,
    message?: ErrorRegisterResponse
  ): void {
    const errorMessage = [
      ...(message?.email ?? []),
      ...(message?.password ?? []),
      ...(message?.confirm_password ?? []),
    ];
    error.register = { value, message: errorMessage };
  }

  return { error, setAuthError, setRegisterError };
});
