<script setup lang="ts">
withDefaults(
  defineProps<{
    label?: string;
    disabled?: boolean;
    name?: string;
    id?: string;
    min?: number;
    max?: number;
  }>(),
  {}
);

const modelValue = defineModel("value");
</script>

<template>
  <div class="x-range" :class="{ 'x-checkbox_disabled': disabled }">
    <input
      type="range"
      :id="id"
      :name="name"
      :min="min"
      :max="max"
      :disabled="disabled"
      v-model="modelValue"
      class="x-range-input"
    />
    <label v-if="label" :for="id">{{ label }}</label>
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.x-range {
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 300ms ease-in-out;
  }

  &[disabled] {
    opacity: 50%;
    background-color: $light-gray;
    color: $gray;
  }

  &_disabled {
    opacity: 50%;
  }
}

.x-range-input {
  width: 100%;
}
</style>
