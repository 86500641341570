<script setup lang="ts"></script>

<template>
  <div class="transition-fade-content">
    <slot />
  </div>
</template>

<style>
.transition-fade-content {
  animation-fill-mode: forwards;
  animation-delay: 400ms;
  animation-duration: 1500ms;
  animation-name: fadeContent;
  opacity: 0;
  flex: 1;

  display: flex;
  flex-direction: column;
}

@keyframes fadeContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
