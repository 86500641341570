import { ref } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useAuthStore = defineStore("auth", () => {
  const initialAuth = Boolean(localStorage.getItem("auth"));
  const isAuth = useLocalStorage("auth", initialAuth);
  function setAuth(value: boolean) {
    isAuth.value = value;

    return;
  }

  const showAuthDialog = ref(false);
  function setShowAuthDialog(value: boolean) {
    showAuthDialog.value = value;

    return;
  }

  return { isAuth, setAuth, showAuthDialog, setShowAuthDialog };
});
