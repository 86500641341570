<script setup lang="ts">
withDefaults(
  defineProps<{
    label: string;
    disabled?: boolean;
    checked?: boolean;
  }>(),
  {
    disabled: false,
    checked: false,
  }
);

const value = defineModel("value");

function stringToId(label: string) {
  return label.toLowerCase().split(" ").join("-");
}
</script>

<template>
  <div class="x-checkbox" :class="{ 'x-checkbox_disabled': disabled }">
    <input
      type="checkbox"
      :id="stringToId(label)"
      :name="stringToId(label)"
      :disabled="disabled"
      :checked="checked"
      v-model="value"
    />
    <label :for="stringToId(label)">{{ label }}</label>
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.x-checkbox {
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 300ms ease-in-out;
  }

  &[disabled] {
    opacity: 50%;
    background-color: $light-gray;
    color: $gray;
  }

  &_disabled {
    opacity: 50%;
  }
}
</style>
