<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import XContent from "@ui/XContent.vue";
import Footer from "../components/footer/Footer.vue";
import markdownit from "markdown-it";
import markdownContent from "@markdown/terms-and-conditiones.md?raw";

const md = (markdownit as any)();
const result = md.render(markdownContent);
</script>

<template>
  <XHeader title="Публічна оферта" />
  <XContent>
    <div v-html="result" />
    <h2>ДОГОВІР</h2>
    <p>
      Публічної оферти про надання консультаційних послуг з питань
      інформатизації.
    </p>
    <p>
      Публічної оферти про надання консультаційних послуг з питань
      інформатизації Цей Договір публічної оферти про надання консультаційних
      послуг з питань інформатизації (надалі – Послуги) встановлює умови та
      порядок надання Послуг на інтернет-платформі, що знаходиться за адресою
      [адреса сайту] (надалі – «Платформа»), та є публічною офертою ФОП Стегній
      І.І. (надалі «Виконавець»), для невизначеного кола осіб у розумінні ст.
      633, 641, 644 Цивільного кодексу України, укласти Договір публічної оферти
      про надання консультаційних послуг з питань інформатизації (надалі за
      текстом – «Договір») на нижчевикладених умовах:
    </p>

    <ol>
      <li>
        Загальні положення
        <ol>
          <li>
            Відносини між Клієнтами та Виконавцем базуються на основі договору
            публічної оферти.
          </li>
          <li>
            Договір Публічної оферти є публічним Договором, що регулюється ст.
            ст. 633, 641 Цивільного кодексу України.
          </li>
          <li>Умови публічного договору є однаковими для всіх.</li>
          <li>
            Придбавши Послуги Виконавця Ви акцептуєте умови цього Договору, та
            підтверджуєте, що ознайомлені з ним та погоджуєтесь з його умовами.
          </li>
          <li>
            У Договорі вживаються терміни в такому значенні:
            <ol>
              <li>
                Платформа – офіційна інтернет-платформа Виконавця, що
                знаходяться в мережі інтернет за адресою [адреса сайту] на якій
                Виконавець розміщує інформацію про свої Послуги, їх вартість, а
                також спосіб отримання. Скориставшись конкретною пропозицією на
                Платформі Клієнт акцептує умови цього Договору.
              </li>
              <li>
                Клієнт – будь-яка фізична особа, юридична особа, фізична
                особа-підприємець, в тому числі – нерезиденти України, що
                замовляють Послуги Виконавця, оплачують за Послуги на
                банківський Рахунок Виконавця, таким чином висловивши намір
                акцептувати цей Договір.
              </li>
              <li>
                Послуги – консультаційні Послуги з питань інформатизації, що
                надаються Виконавцем на користь Клієнтів. Послуги, що надаються
                Виконавцем за цим Договором публікуються на Платформі, що
                знаходиться за адресою [адреса сайту], що вказуються на
                Платформі.
              </li>
              <li>
                Акцептування Договору – під акцептом цього Договору розуміється
                оплата за надані Послуги Виконавцю згідно оголошення чи
                публікації розміщених на Платформі Виконавця. e. Виконавець –
                Фізична особа-підприємець Стегній І.І.
              </li>
            </ol>
          </li>
          <li>
            Зміна або доповнення до цього Договору можливо виключно при
            укладанні письмового Договору. Цей Договір є невід’ємною частиною
            відповідного письмового Договору.
          </li>
          <li>
            Замовлення та подальша оплата Послуг Виконавця, як спосіб прийняття
            умов цього Договору означає згоду Клієнта на обробку своїх
            персональних даних з метою можливості виконання умов цього Договору,
            можливості проведення взаєморозрахунків, а також для отримання
            рахунків, актів та інших документів.
          </li>
          <li>
            Дозвіл на обробку персональних даних діє протягом усього терміну дії
            цього Договору, а також протягом наступних 5 (п’ять) років після
            закінчення дії цього Договору.
          </li>
          <li>
            Знищення персональних даних є підставою для розірвання цього
            Договору і виконується виключно на підставі письмової заяви Клієнта.
            В такому випадку, цей Договір розривається з дати, зазначеної у
            письмовій заяві.
          </li>
          <li>
            Під час укладення цього Договору, суб’єкт персональних даних
            підтверджує, що він повідомлений (без додаткового повідомлення) про
            права, встановлені Законом України «Про захист персональних даних»,
            про цілі збору даних, а також про те, що його персональні дані
            передаються з метою можливості виконання умов цього Договору,
            можливості проведення взаєморозрахунків, а також для отримання
            рахунків, актів та інших документів. Суб’єкт персональних даних
            також погоджується з тим, що Виконавець має право надавати доступ та
            передав��ти його персональні дані третім особам без будь-яких
            додаткових повідомлень суб’єкта персональних даних, не змінюючи при
            цьому мету обробки персональних даних. Обсяг прав суб’єкта
            персональних даних відповідно до Закону України «Про захист
            персональних даних» йому відомий і зрозумілий.
          </li>
        </ol>
      </li>
      <li>
        Предмет Договору
        <ol>
          <li>
            Даний Договір є публічним договором, який вважається укладеним між
            Виконавцем, з однієї сторони, та Клієнтом Платформи, з іншої, з
            моменту Акцепту останнім всіх без винятку умов та положень даного
            Договору та Додатків до нього.
          </li>
          <li>
            Предметом цього Договору є оплатне надання Клієнту консультаційних
            Послуг з питань інформатизації.
          </li>
          <li>
            Виконавець надає послуги в наступному вигляді:
            <ol>
              <li>
                Доступ до електронних сервісів на платформі [адреса сайту] за
                допомогою мережі Інтернет.
              </li>
              <li>
                Дистанційні заходи з використанням трансляції в мережі Інтернет
                (вебінари, трансляції майстер-класів, семінарів, тематичних
                курсів і т.п.).
              </li>
              <li>
                Записи заходів, що надаються в електронному вигляді за допомогою
                мережі Інтернет.
              </li>
              <li>
                Навчальні курси, що надаються в електронному вигляді за
                допомогою мережі Інтернет.
              </li>
            </ol>
          </li>
          <li>
            Виконавець має право в будь-який момент змінювати Календар заходів і
            умови цього Договору в односторонньому порядку без попереднього
            узгодження з Клієнтом, забезпечуючи при цьому публікацію змінених
            умов на Офіційному Веб-сайті Виконавця [адреса сайту], не пізніше
            ніж за один день до їх введення в дію.
          </li>
          <li>
            Цей Договір є офіційним документом, має відповідну юридичну силу і
            публікується на Веб-сайті Виконавця [адреса сайту].
          </li>
          <li>
            Клієнт погоджується, що всі можливі суперечки щодо даного Договору
            будуть вирішуватися відповідно до чинного законодавства України.
          </li>
          <li>
            Надання Послуг Виконавцем за цим Договором здійснюється у
            відповідності з нормами чинного законодавства України та
            домовленостями Сторін.
          </li>
        </ol>
      </li>
      <li>
        Права і обов’язки Сторін
        <ol>
          <li>
            Обов’язки Виконавця:
            <ol>
              <li>
                Своєчасно надавати Послуги Клієнту з дотриманням умов цього
                Договору.
              </li>
              <li>
                Не поширювати отриману від Клієнта або інших джерел інформацію,
                що виходить за рамки умов цього Договору, в ході реалізації
                Виконавцем своїх зобов’язань за цим Договором, згідно чинного
                законодавства України.
              </li>
              <li>
                Негайно інформувати Клієнта про невідповідність наданих
                матеріалів відповідно до вимог Виконавця або чинного
                законодавства України. При цьому термін виконання Послуг може
                переноситися на відповідний термін, протягом якого Клієнт
                надавав відповідні виправлення Виконавцю.
              </li>
              <li>
                Негайно інформувати Клієнта про невідповідність наданих
                матеріалів відповідно до вимог Виконавця або чинного
                законодавства України. При цьому термін виконання Послуг може
                переноситися на відповідний термін, протягом якого Клієнт
                надавав відповідні виправлення Виконавцю.
              </li>
              <li>
                Забезпечити можливість оплати Послуг на банківський рахунок.
              </li>
              <li>
                Забезпечити Клієнта Платформи повною інформацію про Послуги, що
                надаються, їх зміст, строки, а також надати дані про Виконавця
                для пред’явлення скарг або вимог.
              </li>
              <li>
                Ознайомитись з умовами цього Договору та отримати дозвіл на
                обробку персональних даних (в т. ч. в усному порядку).
              </li>
            </ol>
          </li>
          <li>
            Права Виконавця:
            <ol>
              <li>Вносити зміни до Договору в односторонньому порядку.</li>
              <li>
                Вносити зміни щодо інформації та переліку Послуг на власний
                розсуд в односторонньому порядку.
              </li>
              <li>Залучати третіх осіб для виконання умов цього Договору.</li>
              <li>Отримувати оплату від Клієнта за наданні Послуги.</li>
              <li>
                Своєчасно отримувати інформацію від Клієнта, необхідну для
                надання Послуг.
              </li>
            </ol>
          </li>
          <li>
            Обов’язки Клієнта:
            <ol>
              <li>
                Своєчасно і достовірно, а також, в повному обсязі надавати
                Виконавцю всю необхідну інформацію, пов’язану з наданням Послуг.
              </li>
              <li>
                Негайно передавати Виконавцю всі свої побажання по предмету
                даного Договору у письмовому вигляді.
              </li>
              <li>Своєчасно і в повному об’ємі оплачувати Послуги.</li>
              <li>
                Підписати Акт виконаних робіт протягом 10 (десяти) календарних
                днів з моменту його отримання.
              </li>
              <li>
                В разі, якщо Акт виконаних робіт не вимагається Клієнтом
                протягом 10 (десяти) календарних днів після відповідного
                повідомлення Виконавцем про завершення виконання Послуг або не
                підписаний Клієнтом після його отримання протягом 10 (десяти)
                календарних днів, при цьому, Виконавцем не було отримано
                мотивовану відмову від прийняття послуг (підписання Акту), то
                Акт виконаних робіт вважається підписаним, а виконані Послуги
                надані належним чином і в повному обсязі без будь-яких зауважень
                і претензій зі Сторони Клієнта. Правові наслідки такого факту
                прирівнюються до правових наслідків підписання Акту виконаних
                робіт.
              </li>
              <li>Дотримуватись всіх умов та вимог цього Договору.</li>
              <li>Ознайомитись з інформацією про Послуги Виконавця.</li>
            </ol>
          </li>
          <li>
            Права Клієнта:
            <ol>
              <li>
                Інформувати Виконавця про виявлені недоліки у наданні Послуг.
              </li>
              <li>
                Вимагати від Виконавця виконання умов та положень цього
                Договору.
              </li>
              <li>Замовляти Послуги у Виконавця.</li>
              <li>
                Отримати повну інформацію щодо Послуги Виконавця, їх ціну,
                строки виконання та будь-яку іншу інформацію, що стосується
                предмету Договору.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        Відповідальність сторін:
        <ol>
          <li>
            Виконавець за жодних обставин не несе відповідальності за:
            <ol>
              <li>
                Будь-які дії / бездіяльність, що є прямим або непрямим
                результатом дії / бездіяльності будь-яких третіх сторін.
              </li>
              <li>
                Будь-які непрямі збитки та / або упущену вигоду Клієнта та / або
                третіх сторін незалежно від того, міг Виконавець передбачити
                можливість таких збитків чи ні.
              </li>
              <li>
                Використання (неможливість використання) і які б то не було
                наслідки використання (неможливості використання) Клієнтом
                обраної ним форми оплати Послуг за цим Договором.
              </li>
            </ol>
          </li>
          <li>
            У разі невиконання або неналежного виконання однією із Сторін своїх
            зобов’язань згідно цього Договору, Сторони несуть відповідальність
            відповідно до чинного законодавства України.
          </li>
          <li>
            У разі виникнення претензій, суперечок, застосування штрафних
            санкцій з боку третіх осіб і / або контролюючих органів, пов’язаних
            з виконанням Послуг відповідно до даного Договору, Клієнт розглядає
            і вирішує ці питання самостійно, без залучення Виконавця, а також
            зобов’язується невідкладно компенсувати Виконавцю в повному обсязі
            будь-які збитки, витрати, включаючи штрафні санкції, спричинені
            порушенням вищезазначених прав, гарантій і т.д.
          </li>
          <li>
            Виконавець не несе відповідальності за дії (або бездіяльність), а
            також прийняті рішення державних та інших органів, пов’язані з
            виконанням цього Договору.
          </li>
          <li>
            Виконавець не несе відповідальності за збільшення терміну виконання
            Послуг, у разі неоперативності роботи державних та інших органів,
            пов’язаних з виконанням цього Договору.
          </li>
          <li>
            Клієнт несе повну відповідальність за дотримання всіх вимог
            законодавства України та норм міжнародного права, включаючи
            відповідальність за авторські та суміжні права, а також за зміст
            Заявки.
          </li>
          <li>
            У разі порушення Клієнтом умов цього Договору Виконавець має право
            припинити надання Послуг до моменту усунення Клієнтом допущених
            порушень та / або розірвати цей Договір з направленням відповідного
            повідомлення Клієнту за адресою електронної пошти Клієнта, вказаної
            у Заявці.
          </li>
          <li>
            Виконавець має право призупинити виконання Послуг з одночасним
            повідомленням Клієнта в разі недотримання Клієнтом будь-яких
            домовленостей і зобов’язань, обумовлених при укладанні цього
            Договору (або будь-якого іншого Договору з Клієнтом) або в процесі
            його виконання, як в письмовій так і в усній формі.
          </li>
          <li>
            У разі несвоєчасної або неповної оплати Послуг Клієнтом Виконавець
            має право в односторонньому порядку розірвати цей Договір.
          </li>
        </ol>
      </li>
      <li>
        Умови та порядок надання Послуг:
        <ol>
          <li>
            Послуги надаються в повному обсязі за умови їх 100% (сто відсотків)
            оплати Клієнтом.
          </li>
          <li>
            Деякі послуги та заходи, визначені Виконавцем, можуть мати тестовий
            (неоплачуваний) період, тривалість якого становить не більше 7
            (семи) днів. Після закінчення зазначеного періоду, за умови
            продовження участі в заході чи отримуванні послуги, участь в
            визначених заходах є платним. У тому числі до оплати підлягає
            тестовий період.
          </li>
          <li>
            Факт придбання консультаційних послуг є беззаперечним прийняттям
            Клієнтом даного Договору. Клієнт, який скористався послугами
            Виконавця, розглядається як особа, яка вступила з Виконавцем в
            договірні відносини.
          </li>
          <li>
            Оплата заходів (Послуг) проводиться за відповідним посиланням на
            платформі Виконавця [адреса сайту]. Під час оплати Клієнт вказує в
            спеціальній формі (Заявка) на сторінці сайту свої контактні дані.
            Клієнт повністю несе відповідальність за правильність і
            достовірність вказаних контактних даних, оскільки саме за вказаними
            контактними даними проводитиметься обмін інформацією. Після
            здійснення оплати за цим договором без виставлення рахунку, Клієнт
            отримує автоматичне підтвердження на Веб-сайті Виконавця [адреса
            сайту].
          </li>
          <li>
            Посилання на доступ до платних сервісів надсилається Клієнту за
            адресою електронної пошти, вказаною при оформленні Заявки, тільки
            після надходження 100% коштів Клієнта на розрахунковий рахунок
            Виконавця. У разі, якщо Клієнт оплатив і не отримав посилання для
            доступу до платних сервісів через 3 робочі дні після оплати –
            необхідно зв’язатися зі Службою підтримки Виконавця за адресою
            електронної пошти [адреса електронної пошти] (Далі – Служба
            підтримки) надавши копію квитанції про оплату.
          </li>
          <li>
            При належному виконанні Виконавцем своїх зобов’язань за цим
            Договором Клієнт приймає виконані роботи, в тому числі і по Акту
            виконаних робіт, який надсилається Клієнту Виконавцем на його вимогу
            з урахуванням умов 3.1.4, 3.3.5 цього Договору.
          </li>
          <li>
            Клієнт відповідно до п. 3.3.4 цього Договору підписує отриманий Акт
            виконаних робіт, а в разі виявлення недоліків при прийнятті Клієнтом
            наданих Виконавцем Послуг, зобов’язується в той же термін надати
            мотивовану відмову від прийняття робіт (послуг) із зазначенням
            недоліків та строком їх усунення та надсилає його Виконавцю.
            Виконавець зобов’язаний усунути зазначені у відмові недоліки в
            розумний строк і надати новий Акт виконаних робіт Клієнту.
          </li>
          <li>
            У разі непідписання Клієнтом нового Акту виконаних робіт та
            непред’явлення вмотивованої відмови від виконаної роботи в 3-
            (трьох) денний термін, новий Акт вважається підписаним, а робота
            прийнятою. Якщо Акти виконаних робіт не оформлюється, робота
            вважається прийнятою, якщо Клієнт в 10- (десяти) денний термін не
            пред’явить свої письмові претензії Виконавцю. Відсутність вимоги про
            повернення грошових коштів відповідно до пункту 6.7. цього Договору,
            означає, що послуги надані в термін і належної якості.
          </li>
          <li>
            Виконавець залишає за собою право відсторонити Клієнта від заходу
            чи/та доступу до платних сервісів без права на повернення грошових
            коштів у разі порушення правил поведінки на заході, а саме:
            розпалювання міжнаціональних конфліктів, відволікання учасників від
            теми вебінару, спам, розміщення реклами, нецензурні висловлювання,
            хамство, образи ведучого або учасників.
          </li>
          <li>
            Виконавець залишає за собою право здійснювати запис проведених
            заходів.
          </li>
          <li>
            Оплата Послуг здійснюється Клієнтом одним з пропонованих на
            веб-сайті способом. Вибір і використання способу / форми оплати
            Послуг здійснюється Клієнтом на власний розсуд із запропонованих
            варінтів. Безпека, конфіденційність, а також інші умови використання
            обраних Клієнтом способу / форми оплати виходять за рамки даного
            Договору і регулюються угодами (договорами) між Клієнтом та
            відповідними організаціями.
          </li>
          <li>
            На визначенні Виконавцем заходи та платні сервіси, за умови
            письмового звернення Клієнта в Службу Підтримки, діє відпустка (так
            званий – період заморозки). Максимальний період відпустки становить
            30 календарних днів в півріччі. У період відпустки невикористані
            кошти заморожуються на балансі Клієнта.
          </li>
          <li>
            Для активації відпустки Клієнт повинен звернутися в Службу підтримки
            з проханням надання відпустки. Активація відпустки не може бути
            здійснена минулою датою, а також тривати менше 5 календарних днів.
            Активація відпустки відбувається або з дати отримання Службою
            підтримки прохання про надання відпустки, або з дати, яка вказана в
            майбутньому часі. Після закінчення відпустки, яка рівна 30 дням в
            півріччі, відбувається активація раніше сплаченого навчального
            періоду і списання грошових коштів з балансу Клієнта. Якщо Клієнт
            після відпустки не скористався активацією раніше сплаченого
            навчального періоду, Виконавець має право вважати період оплаченого
            доступу до платних сервісів повністю використаним Клієнтом і
            присвоїти Клієнту статус “Відсів”.!!!
          </li>
          <li>
            Поновлення послуг Клієнту надається на підставі нової Заявки через
            ресурси Виконавця або листи до Служби підтримки. При цьому,
            застосовуються умови і тарифи, що діють на момент поновлення послуг.
          </li>
          <li>
            Грошові кошти, сплачені Клієнтом, але не використані ним в рамках
            обраної Послуги, знаходяться на балансі Клієнта, які він може
            використовувати для оплати будь-яких інших послуг Виконавця протягом
            одного місяця, відповідно до пункту 2.3. цього Договору. Якщо Клієнт
            протягом одного місяця не скористався раніше оплаченими грошовими
            коштами, Виконавець має право вважати надані послуги наданими
            належним чином і в повному обсязі.
          </li>
        </ol>
      </li>
      <li>
        Термін дії, зміни умов і розірвання Договору:
        <ol>
          <li>
            Цей Договір вступає в силу з моменту його акцепту Клієнтом та діє до
            остаточного виконання Сторонами зобов’язань по цьому Договору.
          </li>
          <li>
            Всі зміни і доповнення до цього Договору мають юридичну силу, лише в
            разі складання письмового документа, завіреного підписами і
            печатками уповноважених представників Сторін.
          </li>
          <li>Цей Договір може бути розірвано за згодою Сторін.</li>
          <li>
            У разі істотного порушення однією із Сторін умов цього Договору інша
            Сторона має право на одностороннє розірвання цього Договору, про що
            письмово повідомляє Сторону, яка порушила умови Договору, не менше
            ніж за 14 календарних днів.
          </li>
          <li>
            При розірванні цього Договору або припинення його терміну дії вся
            наявна в електронному вигляді інформація Клієнта зберігається у
            Виконавця протягом терміну, регламентованого внутрішніми
            документами.
          </li>
          <li>
            Цей Договір може бути змінений Виконавцем в односторонньому порядку
            для всіх своїх клієнтів з одночасною публікацією нової версії на
            веб-сайті [адреса сайту], а також з повідомленням всіх Клієнтів
            Виконавця за допомогою електронної пошти. У разі незгоди Клієнта з
            внесеними змінами, укладається окрема письмова угода, в якій будуть
            врегульовані спірні питання.
          </li>
          <li>
            Клієнт має право відмовитися від надання оплачених консультаційних
            послуг і вимагати повернення сплачених грошових коштів в наступних
            випадках:
            <ol>
              <li>
                У разі відмови Клієнта від користування сервісами Виконавця до
                моменту надання доступу до платних послуг та сервісів. Якщо
                заявка отримана пізніше, від моменту надання доступу до платних
                послуг та сервісів, платіж не повертається.
              </li>
              <li>
                У разі відмови Клієнта під час отримання доступу до платних
                послуг і сервісів, авансовий платіж повертається частково, в
                розмірі пропорційному неопрацьованим дням користування платними
                сервісами до загальної тривалості оплаченого періоду. Дата
                припинення користування доступом до сервісів/послуг визначається
                датою надходження Заяви про припинення від Клієнта, незалежно
                від кількості успішно опрацьованих днів Клієнтом.
              </li>
            </ol>
          </li>
          <li>
            Для отримання повернення коштів Клієнт повинен надіслати у
            встановлений термін Заяви на повернення в Службу підтримки. Оригінал
            заяви, визначеної форми, заповненої та підписаної Клієнтом,
            надсилається Виконавцю. Оплата здійснюється на рахунок, з якого
            Виконавець отримав оплату. Повернення грошей здійснюється протягом 3
            (трьох) банківських днів з моменту отримання Виконавцем оригіналу
            заяви на повернення.
          </li>
          <li>
            При поверненні коштів оплата комісій банку та вартості транзакції,
            якщо платіж здійснювався за допомогою електронних систем прийому
            платежів, здійснюється за рахунок Клієнта.
          </li>
        </ol>
      </li>
      <li>
        Гарантії:
        <ol>
          <li>
            За винятком гарантій, прямо зазначених в тексті цього Договору,
            Виконавець не надає ніяких інших прямих або непрямих гарантій по
            даному Договору.
          </li>
          <li>
            Погоджуючись з умовами (приймаючи умови) цього Договору, Клієнт
            запевняє Виконавця і гарантує Виконавцю, що:
            <ol>
              <li>
                У разі надання Клієнтом Виконавцю недостовірних даних або
                матеріалів, Клієнт несе повну відповідальність (в тому числі
                майнову) за будь-які негативні наслідки, пов’язані з наданням
                Виконавцем Послуг за цим Договором.
              </li>
              <li>
                Клієнт укладає цей Договір добровільно, при цьому Клієнт
                підтверджує, що:
                <ol>
                  <li>Повністю ознайомився з умовами цього Договору.</li>
                  <li>Повністю розуміє предмет цього Договору.</li>
                  <li>
                    Повністю розуміє значення і наслідки своїх дій щодо
                    укладення та виконання цього Договору.
                  </li>
                </ol>
              </li>
              <li>
                Клієнт має всі права і повноваження, необхідні для укладення та
                виконання цього Договору.
              </li>
            </ol>
          </li>
          <li>
            Вся надана у зв’язку з виконанням цього Договору інформація є
            конфіденційною. Сторони зобов’язуються не розголошувати її третім
            особам і не використовувати в будь-яких цілях, не передбачених цим
            Договором.
          </li>
          <li>
            Дія гарантій, зазначених в цьому Договору, поширюється на
            необмежений період часу і не закінчується після завершення дії цього
            Договору.
          </li>
        </ol>
      </li>
      <li>
        Форс-мажор:
        <ol>
          <li>
            Сторони звільняються від відповідальності за часткове чи повне
            невиконання або неналежне виконання зобов’язань за Договором, якщо
            вони є наслідком непереборної сили (пожежі, повені, землетрусу,
            стихійного лиха, воєнних дій і інших обставин непереборної сили), і
            якщо ці обставини безпосередньо вплинули на виконання Договору, то
            виконання продовжується відповідно на строк, протягом якого діяли ці
            обставини.
          </li>
          <li>
            Якщо форс – мажорні обставини будуть продовжуватися більше трьох
            місяців, то кожна зі Сторін буде мати право відмовитися від
            подальшого виконання зобов’язань за Договором, і в цьому випадку
            Договір вважається припиненим у випадку досягнення Сторонами згоди
            про правові наслідки по всіх умовах даного Договору.
          </li>
        </ol>
      </li>
      <li>
        Інші умови:
        <ol>
          <li>
            У цьому Договорі викладено всі умови, погоджені між Сторонами
            стосовно його предмету і він заміщує собою всі попередні договори,
            заяви та будь-якого характеру домовленості між Сторонами щодо його
            предмету.
          </li>
          <li>
            Договір в будь-який час може бути змінений і/або доповнений
            Виконавцем в односторонньому порядку без попереднього і/або
            подальшого повідомлення Клієнта. Зміни в Договір будуть
            публікуватися із зазначенням дати останнього оновлення на платформі
            Виконавця [адреса сайту]. Клієнт вважається повідомленим про внесені
            до Договору зміни і/або доповнення з моменту опублікування цих змін
            на Платформі.
          </li>
          <li>
            Недійсність, незаконність або непридатність для звернення до
            виконання будь-якого положення цього Договору не тягне за собою
            недійсності, або не впливає на подальшу юридичну силу будь-яких
            інших положень цього Договору.
          </li>
          <li>
            Якщо в цьому Договорі не обумовлюється інше, всі повідомлення
            повинні направлятися на адреси, що зазначені у цьому Договорі чи
            Платформі [адреса сайту].
          </li>
          <li>
            Сторони підтверджують, що в разі, якщо будь-яка умова цього Договору
            стане або буде визнана недійсною у зв’язку з невідповідністю закону,
            то така умова не тягне за собою визнання недійсності будь-якого
            іншого положення цього Договору або Договору в цілому. В такому
            випадку Сторони повинні якнайшвидше вжити заходів щодо зміни
            Договору тією мірою, наскільки це потрібно, щоб замінити недійсне
            положення, щоб у зміненому вигляді воно було законним і по своїй
            суті максимально зберігало початкові наміри Сторін.
          </li>
        </ol>
      </li>
      <li>
        Реквізити Виконавців Фізична особа – підприємець Стегній І.І. Код
        ЄДРПОУ: 3149414980 Aдреса: 40033, Україна, м. Суми, вул. Заливна, б. 13,
        кв. 114 Банківські реквізити: ФОП Стегній Ірина Іванівна IBAN:
        UA373220010000026005340079025 ІПН/ЄДРПОУ: 3149414980 Акціонерне
        товариство: УНІВЕРСАЛ БАНК МФО: 322001 ЄДРПОУ Банку: 21133352
      </li>
    </ol>
  </XContent>

  <Footer />
</template>

<style scoped lang="scss"></style>
