<script setup lang="ts">
withDefaults(
  defineProps<{
    name: string;
    type: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
  }>(),
  {
    required: false,
    disabled: false,
  }
);
const value = defineModel("value");
</script>

<template>
  <div class="x-input" :class="{ 'x-input_disabled': disabled }">
    <label>
      <div v-if="label" class="x-input__label">{{ label }}</div>

      <input
        v-model="value"
        :name="name"
        class="x-input__element"
        :type="type"
        :required="required"
        :disabled="disabled"
      />
    </label>
  </div>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.x-input {
  margin-bottom: 2rem;

  &_disabled {
    opacity: 50%;
  }

  &__element {
    font-size: 1.2rem;
    height: 2rem;
    line-height: 2rem;
    background-color: #f0f0f0;
    border: 0;
    border-radius: 1rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
  }

  &__label {
    font-size: 1rem;
    padding-left: 1rem;
    font-weight: bold;
  }
}
</style>
