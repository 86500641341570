<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
// import XButton from "@ui/XButton.vue";
import XStep from "@ui/XStep.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

type CardSet = {
  id: number;
  name: string;
  cards: number[];
  userSelection: string[];
  requiredCardsNumber: number;
  remainingCardSelectionsLeft: number;
  showCardsFaceUp: boolean;
  isDone: boolean;
};

type CustomerSession = {
  sessionId?: string;
  cardSet?: CardSet | null;
  areAllCardSetsProcessed?: boolean;
};

const route = useRoute();
const session = ref<CustomerSession>();
// const selectedIndex = ref([]);
const intervalCounter = ref(0);

// on load
watch(() => route.params.sessionId as string, loadSessionById, {
  immediate: true,
});

// stop interval after one hour if seesion is not done
watch(session, (newSession) => {
  if (newSession?.areAllCardSetsProcessed) {
    stopInterval();
  }
});

// stop interval after one hour if seesion is not done
watch(intervalCounter, (newIntervalCounter) => {
  if (newIntervalCounter >= 0) {
    stopInterval();
  }
});

async function loadSessionById(sessionId: string) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(
      "https://psy-api.otoselo.com/customer/session/" + sessionId,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      }
    );

    const response = await res.json();
    console.log("🚀 updated session value ", response);
    session.value = response;

    return;
  } catch (e) {
    console.error("handleLoadSessionById ", e);
  }
}

async function handleSelectSetCard(card: number, index: number) {
  console.log(index);
  try {
    // selectedIndex.value = index;
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(
      `https://psy-api.otoselo.com/customer/session/${session?.value?.sessionId}/select-card/${card}`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      }
    );

    const response = await res.json();
    console.log("🚀 ~ handleSelectSetCard ", response);
  } catch (error) {
    console.error(error);
  }
  return;
}

let clientInterval: ReturnType<typeof setInterval>;

function startInterval() {
  clientInterval = setInterval(async () => {
    console.log("setInterval is here");
    if (session.value?.areAllCardSetsProcessed === false) {
      await loadSessionById(route.params.sessionId as string);
    }
  }, 5000);
}

function stopInterval() {
  clearInterval(clientInterval);
  // selectedIndex.value = -1;
  console.log("setInterval is stoped");
}

onMounted(() => {
  startInterval();
});
</script>

<template>
  <XHeader title="Сеанс" />
  <h2 v-if="session?.areAllCardSetsProcessed" style="text-align: center">
    завершено
  </h2>
  <XButton title="Агов! Стоп Інтервал" @click="stopInterval" />
  <div>
    {{ session }}
  </div>
  <hr />

  <!-- v-if="session?.cardSet?.remainingCardSelectionsLeft > 0" -->
  <XStep v-if="session?.cardSet" class="card-rows">
    <div
      v-for="(item, index) in session?.cardSet?.cards"
      v-on.once="true ? { click: () => handleSelectSetCard(item, index) } : {}"
      class="card"
      :class="[
        {
          'card-selected': false,
        },
      ]"
    >
      <img
        v-if="
          session?.cardSet?.showCardsFaceUp ||
          session?.cardSet?.userSelection?.includes(item.toString())
        "
        :src="`/cards/${session?.cardSet?.id}/${item}.jpg`"
        alt=""
        class="card-image"
        :class="[
          {
            'card-image-disabled': false,
            'card-selected': session?.cardSet?.userSelection?.includes(
              item.toString()
            ),
          },
        ]"
      />

      <div v-else class="card-back">&nbsp;</div>
    </div>
  </XStep>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.card-rows {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.card {
  width: 30%;
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 2px;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: 0 0 10px red;
    transition: all 300ms ease-in-out;
  }

  &-selected {
    border: 2px solid blue;
  }
}

.card-image {
  width: 100%;
  height: auto;
  filter: none;
  -webkit-filter: grayscale(0);
  transition: all 300ms ease-in-out;

  &-disabled {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: 0.5;
  }
}

.card-back {
  aspect-ratio: 230/315;
  width: 100%;
  height: auto;
  background-color: $blue;
}
</style>
