<script setup lang="ts">
import XButton from "@ui/XButton.vue";
import XHeader from "@ui/XHeader.vue";
import { useRouter } from "vue-router";

const router = useRouter();

function handleNewSession() {
  router.push("/user/new-session");

  return;
}
</script>

<template>
  <XHeader title="Сеанси" />

  <div class="new-session">
    <XButton title="Створити новий сеанс" @click="handleNewSession" />
  </div>
  <div
    style="
      flex: 1;
      display: flex;
      padding: 1rem 1rem 7rem 1rem;
      width: 100%;
      box-sizing: border-box;
    "
  >
    <div
      style="
        flex: 1;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        color: rgba(0, 0, 0, 0.3);
        font-weight: bold;
        width: 100%;
        justify-content: center;
      "
    >
      Місце для списку завершених сеансів
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-session {
  display: flex;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
</style>
